import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
window.$ = $;
//require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import './lib/foundation-explicit-pieces';


$(document).foundation();


/*---------------------------------------------------
   MOBILE NAV
----------------------------------------------------*/
$( document ).ready(function() {
	
	
	
/*	$('#mobilnav-btn').on('click', function(){
		if($('body').css('margin-left') == '0px'){
			$('#main--navigation .is-drilldown ').animate({
				right: '-230px'
			}, 1000);
			
			$('#mobilnav-btn').children('i').removeClass('fa-bars').addClass('fa-times');

            $('#mobilnav-btn').animate({
				'margin-right': '10px'
			}, 1000);
			$('body').animate({
				'margin-left': '-230px'
			}, 1000);

		} else {
			$('#main--navigation .is-drilldown').animate({
				right: '-230px'
			}, 1000);
			
			$('#mobilnav-btn').children('i').removeClass('fa-times').addClass('fa-bars');
 
            $('#mobilnav-btn').animate({
				'margin-right': '10px'
			}, 1000);
			$('body').animate({
				'margin-left': '0px'
			}, 1000);

			
		}
		return false;
	});
	
	*/
	$('#mobilnav-btn').on('click', function(){
		if($('#mainheader').css('left') == '0px'){
			$('#mainheader').animate({
				left: '-230px'
			}, 1000);

            /*$('#mobilnav-btn').animate({
				'margin-right': '230px'
			}, 1000);*/
			$('body').animate({
				'margin-left': '-230px'
			}, 1000);
			$('#mobilnav-btn').children('i').removeClass('fa-bars').addClass('fa-times');

		} else {
			$('#mainheader').animate({
				left: '0px'
			}, 1000);
 
            /*$('#mobilnav-btn').animate({
				'margin-right': '10px'
			}, 1000);*/
			$('body').animate({
				'margin-left': '0px'
			}, 1000);
			$('#mobilnav-btn').children('i').removeClass('fa-times').addClass('fa-bars');
		}
		return false;
	});

	
});




